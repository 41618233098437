import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const DoDontRow = makeShortcode("DoDontRow");
const DoDont = makeShortcode("DoDont");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Anatomy`}</h2>
    <p>{`The Chips component consists of individual chip elements that are typically displayed in a row or a grid-like layout. Each chip element contains text or icons to represent a specific value or option.`}</p>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/chips/guideline-chips-anatomy.svg",
      "alt": "guideline chips"
    }}></img>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Chips container`}</strong>{`: The main container that holds all the content.`}</li>
      <li parentName="ol"><strong parentName="li">{`Label`}</strong>{`: A label that describes the chips component.`}</li>
      <li parentName="ol"><strong parentName="li">{`Avatar`}</strong>{`: A graphical element that show an avatar of individual.`}</li>
      <li parentName="ol"><strong parentName="li">{`Icon`}</strong>{`: An icon can be clickable to do certain action.`}</li>
    </ol>
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <p>{`To use the Chips component, include the chip elements in your application’s user interface. You can dynamically generate chips based on data or user input, and configure event handlers to respond to interactions with the chips.
The Chips component is a versatile UI element that can be used in various scenarios. Here are some instances where you should consider using the Chips component:`}</p>
    <div className="row1">
  <div className="double-column1 mb-2" style={{
        "justifyContent": "flex-start",
        "flex": "1"
      }}>
    <p className="text-xl font-bold mb-2" style={{
          "color": "#429777"
        }}>
      When to use
    </p>
    <ul>
      <li>When you need to visually represent tags, categories, or labels associated with a piece of content</li>
      <li>When users need to make multiple selections from a predefined set of options</li>
      <li>When you want to display a list of items with the ability to remove or interact with individual items</li>
      <li>When you want to showcase user-selected filters or preferences</li>
    </ul>
  </div>
  <div className="column1 mb-2" style={{
        "justifyContent": "flex-start",
        "flex": "1"
      }}>
    <p className="text-xl font-bold mb-2" style={{
          "color": "#e42313"
        }}>
      When not to use
    </p>
    <ul>
      <li>When the number of items or options is large and may clutter the interface</li>
      <li>
        When the selected options or values are better represented in a different format, such as a dropdown menu or a
        list
      </li>
      <li>When the chips are not interactive and only serve as visual indicators without any further functionality</li>
    </ul>
  </div>
    </div>
    <div className="divi" />
    <h2>{`Accessibility`}</h2>
    <p>{`When implementing the Chips component, it’s important to ensure that it is accessible to all users. Here are some tips for making the Chips component more accessible:`}</p>
    <ul>
      <li parentName="ul">{`Use semantic HTML elements to represent each chip, such as `}{`<`}{`button`}{`>`}{` or `}{`<`}{`div`}{`>`}{`, and include appropriate ARIA roles and attributes.`}</li>
      <li parentName="ul">{`Ensure that each chip is focusable and can be activated using keyboard navigation.`}</li>
      <li parentName="ul">{`Provide clear and concise text labels for each chip, making it easy for screen readers to identify and announce their purpose.`}</li>
      <li parentName="ul">{`Apply contrasting colors and visual cues to highlight selected or focused chips.`}</li>
      <li parentName="ul">{`Test the component with assistive technologies to verify its usability and compatibility.`}</li>
    </ul>
    <DoDontRow mdxType="DoDontRow">
  <DoDont type="do" captionTitle="Do" caption="Provide a way to remove or modify selected chips, if applicable." aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/chips/guideline-chips-a11y-do.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" captionTitle="Don't" caption="Not provide a way to remove or modify selected chips, if applicable." aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/chips/guideline-chips-a11y-dont.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>
    <div className="divi" />
    <h2>{`Content`}</h2>
    <p>{`The content of each chip will depend on the context and purpose of its usage. Here are some general guidelines for creating chip content:`}</p>
    <ul>
      <li parentName="ul">{`Keep the text labels short, descriptive, and relevant to the associated value or option.`}</li>
      <li parentName="ul">{`Consider using icons or visual indicators to supplement the chip’s content.`}</li>
      <li parentName="ul">{`Provide tooltips or additional information for truncated or abbreviated chip labels.`}</li>
      <li parentName="ul">{`Use consistent formatting and styling for chips to maintain a cohesive visual presentation.`}</li>
    </ul>
    <DoDontRow mdxType="DoDontRow">
  <DoDont type="do" captionTitle="Do" caption="Use clear, concise labels or icons for each tab element. So it doesn’t causes some confusion and misunderstanding for the users in using Chips component." aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/chips/guideline-chips-content-do.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" captionTitle="Don't" caption="Use unclear, vague labels or icons for each tab element. Because it could causes some confusion and misunderstanding for the users in using Chips component." aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/chips/guideline-chips-content-dont.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>
    <DoDontRow mdxType="DoDontRow">
  <DoDont type="do" captionTitle="Do" caption="Use appropriate spacing and alignment to maintain visual clarity and prevent overcrowding of chips." aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/chips/guideline-chips-content-do2.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" captionTitle="Don't" caption="Use inappropriate spacing and alignment to maintain visual clarity and prevent overcrowding of chips." aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/chips/guideline-chips-content-dont2.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      